import nl from 'date-fns/locale/nl';
import { format } from 'date-fns';
import { __, curry } from 'ramda';

import { DATE_FORMAT, TIME_FORMAT } from '@/config';

const curriedFormat = curry(format);
const formatWithLocale = curriedFormat(__, __, { locale: nl });

export const date = date =>
  date == null ? null : formatWithLocale(date, DATE_FORMAT);

export const dateTime = date =>
  formatWithLocale(date, `${DATE_FORMAT}, ${TIME_FORMAT}`);

export const time = date => formatWithLocale(date, TIME_FORMAT);
