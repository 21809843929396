import Vue from 'vue';
import VueResource from 'vue-resource';
import { toPairs } from 'ramda';

import App from '@/App.vue';
import * as sharedComponents from '@/components/shared';

import router from '@/router';
import store from '@/store';
import { api, auth } from '@/plugins';
import { date, dateTime, time } from '@/filters';

Vue.use(VueResource);

Vue.filter('date', date);
Vue.filter('dateTime', dateTime);
Vue.filter('time', time);

Vue.config.productionTip = false;
Vue.http.options.root = '/api/v1';

toPairs(sharedComponents).forEach(([key, component]) =>
  Vue.component(key, component),
);

Vue.use(api);
Vue.use(auth);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
