<template>
  <div :style="{minWidth: '180px'}">
    <Datepicker
      v-bind:value="value"
      :day-names="dayNames"
      :month-names="monthNames"
      :date-formatter="dateFormatter"
      icon="calendar"
      position="is-top-right"
      @input="handleInput" />
  </div>
</template>

<script>
import Datepicker from 'buefy/src/components/datepicker';
import { isEqual } from 'date-fns';
import { date as dateFormatter } from '@/filters';
import { dayNames, monthNames } from '@/config';

export default {
  props: ['value'],

  data() {
    return {
      dayNames: dayNames.map(string => string.slice(0, 2)),
      monthNames,
    };
  },

  components: { Datepicker },

  methods: {
    dateFormatter,

    handleInput(newDate) {
      if (isEqual(newDate, this.value)) {
        return;
      }

      this.$emit('input', newDate);
    },
  },
};
</script>

<style>
.datepicker input {
  border-color: transparent;
  box-shadow: none;
}
</style>
