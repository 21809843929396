<template>
  <FetchContainer :endpoints="endpoints">
    <template slot-scope="slotProps">
      <Entities :data="slotProps.data" />
    </template>
  </FetchContainer>
</template>

<script>
import { FetchContainer } from '@/components/shared';
import { entities } from '@/config/endpoints';

import Entities from './Entities';

export default {
  name: 'EntitiesContainer',

  components: { FetchContainer, Entities },

  data() {
    return {
      endpoints: { entities: `${entities}?_size=99` },
    };
  },
};
</script>
