<template>
  <div ref="container" :style="{ fontSize: '80%' }" />
</template>

<script>
import JSONFormatter from 'json-formatter-js';

export default {
  props: ['value'],

  mounted() {
    const json = JSON.parse(this.value);

    const formatter = new JSONFormatter(json, 0);

    const html = formatter.render();

    this.$refs.container.appendChild(html);
  },
};
</script>
