<template>
  <div class="bg-white" :style="{ borderBottom: '1px solid whitesmoke' }">
    <div class="container flex justify-between items-center h-full">
      <div class="flex items-center">
        <div class="flex items-center py-4">
          <span>AIV Openingsuren</span>
          
          <Version class="mx-4" v-if="isAuthenticated" />
        </div>

        <div class="flex items-center" v-if="isAuthenticated">
          <router-link class="nav-item" :to="{ name: 'home' }">Contactlijnen</router-link>

          <router-link class="nav-item" :to="{ name: 'entities' }">Entiteiten</router-link>

          <router-link class="nav-item" :to="{ name: 'channels' }">Kanalen</router-link>

          <router-link class="nav-item" :to="{ name: 'settings' }">Instellingen</router-link>

          <router-link class="nav-item" :to="{ name: 'events' }">Events</router-link>
        </div>
      </div>

      <Dropdown class="is-right" v-if="isAuthenticated">
        <template slot="trigger">
          <div class="rounded-full overflow-hidden cursor-pointer" :style="{ height: '2rem', width: '2rem' }">
            <img :src="profilePic" />
          </div>
        </template>

        <a class="dropdown-item" @click="handleLogOut">Uitloggen</a>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import Version from './Version';

export default {
  components: { Version },

  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },

    profilePic() {
      return this.$store.getters.getUserPicture;
    },
  },

  methods: {
    handleLogOut() {
      this.$auth.logOut();
    },
  },
};
</script>

<style>
.nav-item {
  border-bottom: 2px solid transparent;
  padding: 1rem;
}

.router-link-exact-active {
  border-color: #ffe615;
}
</style>
