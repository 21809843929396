<template>
  <transition name="modal-transition">
    <div class="modal is-active">
      <div class="modal-background" />
      
      <div class="modal-content" ref="container">
        <slot />
      </div>

      <button type="button" class="modal-close is-large" aria-label="close" @click="$emit('closeModal')" />
    </div>
  </transition>
</template>

<script>
export default {
  mounted() {
    window.addEventListener('click', this.handleWindowClick);
  },

  beforeDestroy() {
    window.removeEventListener('click', this.handleWindowClick);
  },

  methods: {
    handleWindowClick(e) {
      const { container } = this.$refs;
      const { top, left, bottom, right } = container.getBoundingClientRect();
      const { clientX, clientY } = e;

      // is enter
      if (clientX === 0 && clientY === 0) {
        return;
      }

      // is on modal content
      if (
        left < clientX &&
        clientX < right &&
        top < clientY &&
        clientY < bottom
      ) {
        return;
      }

      this.$emit('closeModal');
    },
  },
};
</script>


<style lang="scss">
$transition-time: 0.2s;

.modal {
  transition: opacity $transition-time ease;
}

.modal-content {
  transition: all $transition-time ease;
}

.modal-transition-enter {
  opacity: 0;
}

.modal-transition-leave-active {
  opacity: 0;
}

.modal-transition-enter .modal-content,
.modal-transition-leave-active .modal-content {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
