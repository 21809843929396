import nl from 'date-fns/locale/nl';
import { addDays, format, setDay, setMonth } from 'date-fns';
import { __, compose, curry, range } from 'ramda';

import { mergeDateAndTime } from '@/utils/date-utils';

export const DATE_FORMAT = 'D MMMM YYYY';
export const TIME_FORMAT = 'HH:mm';

const [curriedFormat, curriedSetDay, curriedSetMonth] = [
  format,
  setDay,
  setMonth,
].map(curry);

const getDayNameFromIndex = compose(
  curriedFormat(__, 'dddd', { locale: nl }),
  curriedSetDay(new Date()),
);

const getMonthNameFromIndex = compose(
  curriedFormat(__, 'MMMM', { locale: nl }),
  curriedSetMonth(new Date()),
);

export const dayNames = range(0, 7).map(getDayNameFromIndex);
export const monthNames = range(0, 12).map(getMonthNameFromIndex);

export const getDefaultPeriod = day => ({
  startDay: day,
  startTime: '09:00',
  endDay: day,
  endTime: '17:00',
});

export const defaultOpeningHours = range(1, 6).map(getDefaultPeriod); // Mon-Fri

export const getDefaultException = (
  infoText = 'We zijn momenteel uitzonderlijk gesloten',
) => {
  const startDate = mergeDateAndTime(new Date(), '00:00');

  return {
    startDate,
    endDate: addDays(startDate, 1),
    infoText,
  };
};

export const reasonsForBeingClosed = {
  closed: 'Het is buiten openingsuren.',
  settings: 'De kill switch staat aan.',
  channel: 'Het kanaal staat uit.',
  entity: 'De entiteit staat uit.',
  contactLine: 'De contactlijn staat uit.',
  exceptionally_closed: 'Er is een uitzondering van toepassing.',
};
