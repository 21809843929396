const recursivelyTransformKeys = fn => input => {
  if (typeof input !== 'object') {
    return input;
  }

  if (Array.isArray(input)) {
    return input.map(recursivelyTransformKeys(fn));
  }

  return Object.entries(input).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [fn(key)]: recursivelyTransformKeys(fn)(value),
    }),
    {},
  );
};

export default recursivelyTransformKeys;
