<template>
  <span class="icon">
    <i :class="`mdi mdi-24px mdi-${iconName}`" />
  </span>
</template>

<script>
export default {
  props: ['iconName'],
};
</script>
