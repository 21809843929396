import jwtDecode from 'jwt-decode';
import { compose, pick, prop } from 'ramda';

import { UPDATE_AUTH } from '@/constants';

const getUserProfile = () => {
  try {
    const idToken = localStorage.getItem('id_token');

    return compose(
      pick(['email', 'name', 'nickname', 'picture']),
      jwtDecode,
    )(idToken);
  } catch (e) {
    return null;
  }
};

const getExpiresAt = () => localStorage.getItem('expires_at');

export default {
  state: {
    error: null,
    expiresAt: getExpiresAt(),
    userProfile: getUserProfile(),
  },

  mutations: {
    [UPDATE_AUTH]: (state, error = null) => {
      state.error = error;
      state.expiresAt = getExpiresAt();
      state.userProfile = getUserProfile();
    },
  },

  getters: {
    getAuthError: prop('error'),
    getUserProfile: prop('userProfile'),
    isAuthenticated: ({ expiresAt = 0, userProfile }) =>
      userProfile && new Date().getTime() < Number(expiresAt),
    getUserPicture: ({ userProfile }) => userProfile && userProfile.picture,
  },
};
