import { setHours, setMinutes } from 'date-fns';
import { __, compose, curry } from 'ramda';

const [curriedSetHours, curriedSetMinutes] = [setHours, setMinutes].map(curry);

export const mergeDateAndTime = (date, time) => {
  const [hours, minutes] = time.split(':');

  return compose(
    curriedSetMinutes(__, minutes),
    curriedSetHours(__, hours),
  )(date);
};
