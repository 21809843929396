<template>
  <transition name="toast-transition">
    <div v-if="!!state.message" class="toast flex justify-center absolute w-full mt-8" :style="{ zIndex: 10 }">
      <div class="notification flex" :class="state.type">
        <Icon :iconName="iconName" class="mr-4" />

        {{ state.message}}
      </div>
    </div>
  </transition>
</template>

<script>
import { Icon } from '@/components/shared';

const iconNameConfig = {
  'is-danger': 'alert',
  'is-success': 'check',
};

export default {
  components: { Icon },

  computed: {
    iconName() {
      return iconNameConfig[this.state.type] || 'info';
    },

    state() {
      return this.$store.state.toast;
    },
  },
};
</script>

<style lang="scss" scoped>
$transition-time: 0.2s;

.toast {
  transition: opacity $transition-time ease;
}

.toast-transition-enter {
  opacity: 0;
}

.toast-transition-leave-active {
  opacity: 0;
}
</style>
