<template>
  <div class="field">
    <div class="control has-icons-left">
      <input
        type="text"
        class="input"
        ref="input"
        :class="error ? 'is-danger' : ''"
        v-bind:value="localValue"
        v-on:blur="handleBlur"
        required />

      <Icon class="is-left" iconName="clock-outline" />
    </div>

    <p class="help is-danger" v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import { format, isValid } from 'date-fns';

import { Icon } from '@/components/shared';
import { TIME_FORMAT } from '@/config';
import { mergeDateAndTime } from '@/utils/date-utils';

export default {
  components: { Icon },

  props: ['value'],

  data() {
    return {
      error: null,
    };
  },

  computed: {
    localValue() {
      return this.value;
    },
  },

  methods: {
    handleBlur({ target: { value } }) {
      const date = mergeDateAndTime(new Date(), value);

      if (!isValid(date)) {
        this.error = 'Bijv. 09:15';
        return;
      }

      this.error = null;
      this.$emit('input', format(date, TIME_FORMAT));
    },
  },
};
</script>

<style lang="scss" scoped>
.select select,
input {
  border-color: transparent;
  box-shadow: none;
}
</style>
