<template>
  <div>
    <div class="flex justify-between flex-wrap">
      <h3 class="title">Lijst van events</h3>
      
      <div class="flex">
        <SearchField @change="filter = $event" />
      </div>
    </div>

    <TableContainer
      :config="tableConfig"
      :data="tableData"
      defaultSortDirection="DESC"
      defaultSortByKey="created"
      :filter="filter"
      :filterBy="['user', 'message']" />
  </div>
</template>

<script>
import { compose, pick, prop } from 'ramda';
import { dateTime } from '@/filters';

const EventMessage = {
  functional: true,
  props: ['message', 'type'],
  render: (createElement, { props: { message, type } }) =>
    createElement(
      'div',
      { class: `notification is-narrow is-${type}` },
      message,
    ),
};

const tableConfig = [
  {
    key: 'created',
    label: 'Datum',
    cellDataGetter: compose(
      dateTime,
      prop('created'),
    ),
    sortDataGetter: prop('created'),
  },
  {
    key: 'user',
    label: 'Gebruiker',
    cellDataGetter: prop('user'),
  },
  {
    key: 'event',
    label: 'Event',
    cellDataGetter: pick(['message', 'type']),
    cellRenderer: EventMessage,
    sortable: false,
  },
];

export default {
  name: 'Events',

  props: ['data'],

  data() {
    return {
      filter: '',
      tableConfig,
      tableData: this.data.events,
    };
  },
};
</script>
