<template>
  <FetchContainer :endpoints="endpoints">
    <template slot-scope="slotProps">
      <Events :data="slotProps.data" />
    </template>
  </FetchContainer>
</template>

<script>
import { events } from '@/config/endpoints';

import Events from './Events';

export default {
  name: 'EventsContainer',

  components: { Events },

  data() {
    return {
      endpoints: { events: `${events}?_size=99&_sort=-created` },
    };
  },
};
</script>
