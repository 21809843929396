<template>
  <div>
    <div class="flex justify-between flex-wrap">
      <h3 class="title mb-6">Nieuw kanaal toevoegen</h3>
    </div>

    <EntityForm :initialValues="initialValues" :onCancel="handleCancel" :onSubmit="handleSubmit" />
  </div>
</template>

<script>
import EntityForm from '@/components/EntityForm';
import { HorizontalWrapper } from '@/components/FormFields';
import { channels } from '@/config/endpoints';
import { SHOW_TOAST } from '@/constants';

export default {
  name: 'NewChannel',

  components: { EntityForm, HorizontalWrapper },

  data() {
    return {
      initialValues: {
        name: '',
        active: true,
        exceptionallyClosed: '',
      },
      showModal: false,
    };
  },

  methods: {
    handleCancel() {
      this.$router.push({ name: 'channels' });
    },

    handleError({ message }) {
      this.showModal = false;

      this.$store.dispatch(SHOW_TOAST, {
        message,
        type: 'is-danger',
      });
    },

    handleSubmit(dirtyData) {
      this.$api(channels, {
        body: dirtyData,
        method: 'put',
      })
        .then(() => this.handleSuccess('Kanaal toegevoegd'))
        .catch(this.handleError);
    },

    handleSuccess(message) {
      this.$store.dispatch(SHOW_TOAST, {
        message,
        type: 'is-success',
      });

      this.$router.push({ name: 'channels' });
    },
  },
};
</script>
