// based on https://auth0.com/docs/quickstart/spa/vuejs

import auth0 from 'auth0-js';

import router from '@/router';
import store from '@/store';

import { UPDATE_AUTH } from '@/constants';

const AUTH0_DOMAIN = 'aiv-opening-hours.eu.auth0.com';
const AUTH0_CLIENT_ID = 'gaOoV7ArUJbaiFAypAAxxQ43zMPozS34';

const callbackUri = `${window.location.origin}/callback`;
const LOGIN_PATH = '/login';

class AuthService {
  auth0 = new auth0.WebAuth({
    audience: 'opening-hours',
    clientID: AUTH0_CLIENT_ID,
    domain: AUTH0_DOMAIN,
    responseType: 'token id_token',
    realm: 'Username-Password-Authentication',
  });

  handleAuthentication() {
    return new Promise(resolve => {
      this.auth0.parseHash((error, authResult) => {
        if (error) {
          return this.handleError(error);
        }

        if (authResult == null) {
          return router.replace(LOGIN_PATH);
        }

        this.setSession(authResult);

        resolve();
      });
    });
  }

  loginWithEmail = (
    credentials,
    redirectUri = callbackUri,
    callback = this.handleError,
  ) => {
    this.auth0.login(
      {
        ...credentials,
        redirectUri,
      },
      callback,
    );
  };

  logOut = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');

    router.replace(LOGIN_PATH);

    store.commit(UPDATE_AUTH);
  };

  handleError = error => {
    store.commit(UPDATE_AUTH, error);
    router.replace(LOGIN_PATH);
  };

  resetPassword = (email = store.getters.getUserProfile, callback) => {
    this.auth0.changePassword(
      {
        connection: 'Username-Password-Authentication',
        email,
      },
      callback,
    );
  };

  setSession = ({ accessToken, expiresIn, idToken }) => {
    const expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime());

    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('id_token', idToken);
    localStorage.setItem('expires_at', expiresAt);

    store.commit(UPDATE_AUTH);
  };

  signUp = (credentials, callback) => {
    this.auth0.signup(
      {
        ...credentials,
        connection: 'Username-Password-Authentication',
      },
      callback,
    );
  };
}

export default {
  install: Vue => {
    Vue.prototype.$auth = new AuthService();
  },
};
