<template>
  <FetchContainer :endpoints="endpoints">
    <template slot-scope="slotProps">
      <Settings :data="slotProps.data" />
    </template>
  </FetchContainer>
</template>

<script>
import { settings } from '@/config/endpoints';

import Settings from './Settings';

export default {
  name: 'SettingsContainer',

  components: { Settings },

  data() {
    return {
      endpoints: { settings },
    };
  },
};
</script>
