<template>
  <FetchContainer :endpoints="endpoints">
    <template slot-scope="slotProps">
      <Channels :data="slotProps.data" />
    </template>
  </FetchContainer>
</template>

<script>
import { FetchContainer } from '@/components/shared';
import { channels } from '@/config/endpoints';

import Channels from './Channels';

export default {
  name: 'ChannelsContainer',

  components: { FetchContainer, Channels },

  data() {
    return {
      endpoints: { channels: `${channels}?_size=99` },
    };
  },
};
</script>
