import { curry, find, propEq } from 'ramda';

export const findEnforcing = (array, fn, trace = '') => {
  const found = find(fn, array);

  if (found == null) {
    throw new Error(`Element not found in array ${trace}`);
  }

  return found;
};

export const findNameById = (array, id, trace) => {
  const fn = item => item.id === id;

  const { name } = findEnforcing(array, fn, trace);

  return name;
};

export const reverseDirection = direction =>
  direction === 'ASC' ? 'DESC' : 'ASC';

export const findInArrayByProp = curry((array = [], prop, value) =>
  find(propEq(prop, value), array),
);

export const findInArrayByPropEnforcing = curry((array, prop, value) => {
  const found = findInArrayByProp(array, prop, value);

  if (found == null) {
    throw new Error(`Item with { ${prop}: ${value} } not found in array`);
  }

  return found;
});

export const serializeDate = date => date.getTime();
