<template>
  <FetchContainer :endpoints="endpoints">
    <template slot-scope="slotProps">
      <span class="tag" :class="getClass(slotProps.data.response.env)">
        {{ slotProps.data.response.env }} / v{{ slotProps.data.response.version }}
      </span>
    </template>
  </FetchContainer>
</template>

<script>
import { version } from '@/config/endpoints';

export default {
  name: 'Version',

  data() {
    return {
      endpoints: { response: version },
    };
  },

  methods: {
    getClass(env) {
      switch (env) {
        case 'development':
          return 'is-success';

        case 'staging':
          return 'is-warning';

        case 'production':
          return 'is-danger';

        default:
          return null;
      }
    },
  },
};
</script>
