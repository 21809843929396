<template>
  <FetchContainer :endpoints="endpoints">
    <template slot-scope="slotProps">
      <NewContactLine :data="slotProps.data" :refetch="slotProps.fetch" />
    </template>
  </FetchContainer>
</template>

<script>
import { FetchContainer } from '@/components/shared';
import { channels, entities } from '@/config/endpoints';

import NewContactLine from './NewContactLine';

export default {
  name: 'NewContactLineContainer',

  components: { NewContactLine, FetchContainer },

  data() {
    return {
      endpoints: { channels: `${channels}?_size=99`, entities: `${entities}?_size=99` },
    };
  },
};
</script>
