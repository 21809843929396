<template>
  <FetchContainer :endpoints="endpoints">
    <template slot-scope="slotProps">
      <EditEntity :entity="sanitize(slotProps)" />
    </template>
  </FetchContainer>
</template>

<script>
import { compose, head, path } from 'ramda';
import { FetchContainer } from '@/components/shared';
import { entityById } from '@/config/endpoints';

import EditEntity from './EditEntity';

export default {
  name: 'EditEntityContainer',

  components: { EditEntity, FetchContainer },

  data() {
    const { id } = this.$route.params;

    return {
      endpoints: {
        entity: entityById(id),
      },
    };
  },

  methods: {
    sanitize: compose(
      head,
      path(['data', 'entity']),
    ),
  },
};
</script>
