import { path, prop } from 'ramda';
import { DEFAULT_ERROR_MESSAGE } from '@/constants';
import { snakify, camelize } from '@/utils/sanitize';

const getErrorMessage = ({ body }) => {
  // todo: ensure api has single error format
  const possiblePaths = [
    ['message'],
    ['error', 'sqlMessage'],
    ['errors', '0', 'msg'],
  ];

  return (
    possiblePaths.reduce((acc, val) => acc || path(val, body), null) ||
    DEFAULT_ERROR_MESSAGE
  );
};

export default {
  install: Vue => {
    Vue.prototype.$api = (url, { method = 'get', body, ...options }) =>
      Vue.http({
        url,
        method,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: body ? snakify(body) : null,
        ...options,
      })
        .then(prop('body'))
        .then(camelize)
        .catch(response => {
          if (response.status === 401) {
            Vue.prototype.$auth.logOut();
          }

          throw new Error(`ERROR: ${getErrorMessage(response)}`);
        });
  },
};
