import Vue from 'vue';
import Router from 'vue-router';

import store from '@/store';
import {
  ContactLinesContainer,
  EditContactLineContainer,
  NewContactLineContainer,
} from '@/views/ContactLines';
import {
  EditEntityContainer,
  EntitiesContainer,
  NewEntity,
} from '@/views/Entities';
import {
  EditChannelContainer,
  ChannelsContainer,
  NewChannel,
} from '@/views/Channels';
import { SettingsContainer } from '@/views/Settings';
import { EventsContainer } from '@/views/Events';
import Login from '@/views/Login';
import Callback from '@/views/Callback';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      alias: '/contact-lines',
      name: 'home',
      component: ContactLinesContainer,
    },
    {
      path: '/contact-lines/new',
      name: 'newLine',
      component: NewContactLineContainer,
    },
    {
      path: '/contact-lines/:id',
      name: 'editLine',
      component: EditContactLineContainer,
    },
    {
      path: '/entities',
      name: 'entities',
      component: EntitiesContainer,
    },
    {
      path: '/entities/new',
      name: 'newEntity',
      component: NewEntity,
    },
    {
      path: '/entities/:id',
      name: 'editEntity',
      component: EditEntityContainer,
    },
    {
      path: '/channels',
      name: 'channels',
      component: ChannelsContainer,
    },
    {
      path: '/channels/new',
      name: 'newChannel',
      component: NewChannel,
    },
    {
      path: '/channels/:id',
      name: 'editChannel',
      component: EditChannelContainer,
    },
    {
      path: '/settings',
      name: 'settings',
      component: SettingsContainer,
    },
    {
      path: '/events',
      name: 'events',
      component: EventsContainer,
    },
    {
      path: '/callback',
      component: Callback,
      meta: {
        requiresLoggedIn: false,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        requiresLoggedIn: false,
      },
    },
  ],
});

router.beforeEach(
  ({ meta }, from, next) =>
    !store.getters.isAuthenticated && meta.requiresLoggedIn !== false
      ? next('/login')
      : next(),
);

export default router;
