<template>
  <table :class="`table is-fullwidth is-hoverable ${onRowClick ? 'is-clickable' : ''}`">
    <thead>
      <tr>
        <th
          v-for="({ label, key, sortable = true, subLabel, title }) in config"
          :key="key"
          :title="title || label">
          <div class="inline-flex items-center cursor-pointer text-sm" @click="sortable && updateSort(key)">
            <span class="uppercase">{{ label }}</span>

            <svg width="18" height="18" viewBox="0 0 24 24" v-if="sortable">
              <path :d="sortDirection === 'DESC' ? 'M7 14l5-5 5 5z' : 'M7 10l5 5 5-5z'" v-if="sortByKey === key" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </div>

          <div class="text-sm has-text-grey-light font-normal" v-if="subLabel" :style="{ whiteSpace: 'normal' }">
            {{ subLabel }}
          </div>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr
        v-for="row in data"
        :key="row.id"
        @click="handleOnClick(row)">
        <td v-for="{ key, cellDataGetter = () => null, cellRenderer = 'div', style = {} } in config" :key="key" :style="style">
          <component
            :is="cellRenderer"
            v-bind="typeof cellDataGetter(row) === 'object' ? cellDataGetter(row) : { value: cellDataGetter(row) }">
            {{ cellDataGetter(row) }}
          </component>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: [
    'config',
    'data',
    'onRowClick',
    'sortByKey',
    'sortDirection',
    'updateSort',
  ],

  methods: {
    handleOnClick(row) {
      if (this.onRowClick) {
        this.onRowClick(row);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table.is-clickable tbody tr {
  cursor: pointer;
}

th {
  white-space: nowrap;
}
</style>
