<template>
  <FetchContainer :endpoints="endpoints">
    <template slot-scope="slotProps">
      <EditContactLine :data="desanitize(slotProps.data)" />
    </template>
  </FetchContainer>
</template>

<script>
import { evolve } from 'ramda';
import { FetchContainer } from '@/components/shared';
import { channels, contactLineById, entities } from '@/config/endpoints';

import EditContactLine from './EditContactLine';
import { desanitizeContactLine } from './utils';

export default {
  name: 'EditContactLineContainer',

  components: { EditContactLine, FetchContainer },

  data() {
    const { id } = this.$route.params;

    return {
      endpoints: {
        channels: `${channels}?_size=99`,
        entities: `${entities}?_size=99`,
        contactLine: contactLineById(id),
      },
    };
  },

  methods: {
    desanitize(data) {
      return evolve({
        contactLine: desanitizeContactLine,
      })(data);
    },
  },
};
</script>
