<template>
  <form @submit.prevent="handleSubmit">
    <HorizontalWrapper label="Gecreeerd" v-if="isEdit">
      <input :value="created | dateTime" type="text" class="input" disabled />
    </HorizontalWrapper>

    <HorizontalWrapper label="Laatst geupdate" v-if="isEdit">
      <input :value="updated | dateTime" type="text" class="input" disabled />
    </HorizontalWrapper>

    <HorizontalWrapper label="Naam">
      <input v-model="name" type="text" class="input" required />
    </HorizontalWrapper>

    <HorizontalWrapper label="Is actief?">
      <div class="field" :style="{ marginTop: '0.375em' }">
        <input type="checkbox" id="isActive" class="switch" v-model="active" />

        <label for="isActive" />
      </div>
    </HorizontalWrapper>

    <HorizontalWrapper label="Informatietekst wanneer niet actief">
      <input v-model="exceptionallyClosed" type="text" class="input" />
    </HorizontalWrapper>

    <ConfirmOrCancel :onCancel="onCancel" />
  </form>
</template>

<script>
import { ConfirmOrCancel, HorizontalWrapper } from '@/components/FormFields';
import { dirtyData } from '@/mixins';

export default {
  mixins: [dirtyData],

  props: ['isEdit', 'onCancel'],

  components: { ConfirmOrCancel, HorizontalWrapper },
};
</script>
