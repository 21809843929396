<template>
  <div class="search-field control has-icons-right">
    <input
      type="text"
      class="input"
      v-model="value"
      :placeholder="placeholder || 'Typ om te filteren...'"
      @input="$emit('change', value)" />

    <span class="icon is-right" @click="clear">
      <i class="mdi mdi-24px mdi-magnify" />
    </span>
  </div>
</template>

<script>
export default {
  props: ['placeholder'],

  data() {
    return {
      value: '',
    };
  },

  methods: {
    clear() {
      this.$emit('change', '');
      this.value = '';
    },
  },
};
</script>
