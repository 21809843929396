<template>
  <FetchContainer :endpoints="endpoints">
    <template slot-scope="slotProps">
      <EditChannel :channel="sanitize(slotProps)" />
    </template>
  </FetchContainer>
</template>

<script>
import { compose, head, path } from 'ramda';
import { FetchContainer } from '@/components/shared';
import { channelById } from '@/config/endpoints';

import EditChannel from './EditChannel';

export default {
  name: 'EditChannelContainer',

  components: { EditChannel, FetchContainer },

  data() {
    const { id } = this.$route.params;

    return {
      endpoints: {
        channel: channelById(id),
      },
    };
  },

  methods: {
    sanitize: compose(
      head,
      path(['data', 'channel']),
    ),
  },
};
</script>
