<template>
  <tr>
    <th class="align-middle">{{ index + 1 }}</th>

    <td>
      <Datepicker v-bind:value="startDate" @input="handleStartDateChange" />
    </td>
    
    <td>
      <TimePicker v-bind:value="startTime" @input="handleStartTimeChange" />
    </td>
    
    <td>
      <Datepicker v-bind:value="endDate" @input="handleEndDateChange" />
    </td>
    
    <td>
      <TimePicker v-bind:value="endTime" @input="handleEndTimeChange" />
    </td>

    <td>
      <input v-model="localValue.infoText" type="text" class="input" />
    </td>
    
    <td>
      <button type="button" class="button is-danger" @click.prevent="handleDelete">
        <Icon iconName="close" />
      </button>
    </td>
  </tr>
</template>

<script>
import { mergeDateAndTime } from '@/utils/date-utils';
import TimePicker from './TimePicker';
import Datepicker from './Datepicker';
import { Icon } from '@/components/shared';
import { time as dateToTime } from '@/filters';

export default {
  components: { Datepicker, Icon, TimePicker },

  props: ['index', 'value', 'onDelete'],

  computed: {
    localValue() {
      return this.value;
    },

    startTime() {
      return dateToTime(this.localValue.startDate);
    },

    startDate() {
      return this.localValue.startDate;
    },

    endTime() {
      return dateToTime(this.localValue.endDate);
    },

    endDate() {
      return this.localValue.endDate;
    },
  },

  methods: {
    handleDelete() {
      this.onDelete(this.index);
    },

    handleEndDateChange(date) {
      this.localValue.endDate = mergeDateAndTime(date, this.endTime);
    },

    handleEndTimeChange(time) {
      this.localValue.endDate = mergeDateAndTime(this.endDate, time);
    },

    handleStartDateChange(date) {
      this.localValue.startDate = mergeDateAndTime(date, this.startTime);
    },

    handleStartTimeChange(time) {
      this.localValue.startDate = mergeDateAndTime(this.startDate, time);
    },
  },
};
</script>
