<template>
  <div class="dropdown max-w-full" :class="{ 'is-active': isActive }">
    <div class="dropdown-trigger max-w-full" @click="toggle">
      <slot name="trigger" />
    </div>

    <div class="dropdown-menu">
      <div class="dropdown-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
    };
  },

  methods: {
    handleWindowClick(e) {
      if (this.isActive) {
        this.toggle(e);
      }
    },

    toggle(e) {
      e.preventDefault();
      e.stopPropagation();

      this.isActive = !this.isActive;
    },
  },

  mounted() {
    window.addEventListener('click', this.handleWindowClick);
  },

  beforeDestroy() {
    window.removeEventListener('click', this.handleWindowClick);
  },
};
</script>
