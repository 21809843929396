<template>
  <div>
    <Loading v-if="isLoading" />

    <div class="notification is-danger" v-else-if="error">{{ error }}</div>

    <slot :data="data" :fetch="fetch" v-else />
  </div>
</template>

<script>
import Loading from './Loading';
import { values } from 'ramda';

export default {
  name: 'FetchContainer',

  props: ['endpoints'],

  components: { Loading },

  data() {
    return {
      isLoading: true,
      error: null,
      data: null,
    };
  },

  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      const [endpointKeys, endpointValues] = [
        Object.keys(this.endpoints),
        values(this.endpoints),
      ];

      Promise.all(endpointValues.map(this.$api))
        .then(data => {
          this.isLoading = false;
          this.data = endpointKeys.reduce(
            (acc, key, index) => ({
              ...acc,
              [key]: data[index],
            }),
            {},
          );
        })
        .catch(({ message }) => {
          this.isLoading = false;
          this.error = message;
        });
    },
  },
};
</script>
