<template>
  <div>
    <div class="flex items-center mb-4 pt-2">
      <Icon iconName="alert-circle-outline" />&nbsp;Deze geven weer wanneer een lijn gesloten is.
    </div>

    <div class="box inline-block">
      <table class="table" v-if="value.length > 0">
        <thead>
          <tr>
            <th>#</th>
            <th colspan="2">Van</th>
            <th colspan="2">Tot</th>
            <th>Info Tekst</th>
            <th />
          </tr>
        </thead>

        <tbody>
          <Exception
            v-for="(exception, index) in value"
            :key="index"
            :index="index"
            :onDelete="handleDeleteException"
            v-bind:value="exception" />
        </tbody>
      </table>

      <button type="button" class="button" @click.prevent="handleAddException">
        <Icon iconName="plus" />

        <span>Uitzondering toevoegen</span>
      </button>
    </div>
  </div>
</template>

<script>
import { remove } from 'ramda';
import Exception from './Exception';

import { getDefaultException } from '@/config';

export default {
  props: ['value'],

  components: { Exception },

  methods: {
    handleAddException() {
      this.$emit('input', [...this.value, getDefaultException()]);
    },

    handleDeleteException(index) {
      this.$emit('input', remove(index, 1, this.value));
    },
  },
};
</script>
