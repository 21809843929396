<template>
  <div id="app" class="flex flex-col overflow-hidden h-screen bg-lightest-grey">
    <Header />

    <PageContainer>
      <div v-if="error" class="notification is-danger">{{ error }}</div>
      
      <router-view v-else/>
    </PageContainer>

    <Toast />
  </div>
</template>

<script>
import Header from '@/components/Header';
import Toast from '@/components/Toast';
import { PageContainer } from '@/components/shared';

export default {
  components: { Header, PageContainer, Toast },

  data() {
    return {
      error: null,
    };
  },

  errorCaptured(error) {
    this.error = error;
  },
};
</script>


<style lang="scss">
@import 'styles/main';
</style>
