<template>
  <FetchContainer :endpoints="endpoints">
    <template slot-scope="slotProps">
      <ContactLines :data="slotProps.data" />
    </template>
  </FetchContainer>
</template>

<script>
import { FetchContainer } from '@/components/shared';
import { contactLines } from '@/config/endpoints';

import ContactLines from './ContactLines';

export default {
  name: 'ContactLinesContainer',

  components: { FetchContainer, ContactLines },

  data() {
    return {
      endpoints: { contactLines },
    };
  },
};
</script>
