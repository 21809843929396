import { compose, evolve, map, pick } from 'ramda';

const makeTimestamp = date => date.getTime();

const sanitizeException = evolve({
  startDate: makeTimestamp,
  endDate: makeTimestamp,
});

const validProps = [
  'active',
  'channelId',
  'closed',
  'entityId',
  'exceptions',
  'exceptionallyClosed',
  'information',
  'openingHours',
];

export const sanitizeContactLine = compose(
  evolve({
    exceptions: map(sanitizeException),
  }),
  pick(validProps),
);

const makeDate = string => new Date(string);

const desanitizeException = evolve({
  endDate: makeDate,
  startDate: makeDate,
});

export const desanitizeContactLine = evolve({
  exceptions: map(desanitizeException),
});
