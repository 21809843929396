<template>
  <div>
    <div class="flex justify-between flex-wrap">
      <h3 class="title mb-6">Entiteit bewerken</h3>

      <button class="button is-danger" @click="showModal = true">
        <Icon iconName="delete-outline" />

        <span>Entiteit verwijderen</span>
      </button>
    </div>

    <EntityForm :initialValues="initialValues" :isEdit="true" :onCancel="handleCancel" :onSubmit="handleSubmit" />    

    <Modal v-if="showModal" @closeModal="showModal = false">
      <AreYouSure :onConfirm="handleDelete" :onCancel="() => showModal = false" />
    </Modal>
  </div>
</template>

<script>
import EntityForm from '@/components/EntityForm';
import { HorizontalWrapper } from '@/components/FormFields';
import { entityById } from '@/config/endpoints';
import { SHOW_TOAST } from '@/constants';

export default {
  name: 'EditEntity',

  props: ['entity'],

  components: { EntityForm, HorizontalWrapper },

  data() {
    return {
      initialValues: this.entity,
      showModal: false,
    };
  },

  methods: {
    handleCancel() {
      this.$router.push({ name: 'entities' });
    },

    handleDelete() {
      this.$api(entityById(this.entity.id), {
        method: 'delete',
      })
        .then(() => this.handleSuccess('Entiteit verwijderd'))
        .catch(this.handleError);
    },

    handleError({ message }) {
      this.showModal = false;

      this.$store.dispatch(SHOW_TOAST, {
        message,
        type: 'is-danger',
      });
    },

    handleSubmit(dirtyData) {
      this.$api(entityById(this.entity.id), {
        body: dirtyData,
        method: 'patch',
      })
        .then(() => this.handleSuccess('Entiteit bewerkt'))
        .catch(this.handleError);
    },

    handleSuccess(message) {
      this.$store.dispatch(SHOW_TOAST, {
        message,
        type: 'is-success',
      });

      this.$router.push({ name: 'entities' });
    },
  },
};
</script>
