<template>
  <div>
    <div class="flex justify-between flex-wrap">
      <h3 class="title mb-6">Nieuwe contactlijn toevoegen</h3>
    </div>

    <ContactLineForm
      :channels="localValues.channels"
      :entities="localValues.entities"
      :isEdit="false"
      :initialValues="initialValues"
      :onCancel="handleCancel"
      :onSubmit="handleSubmit"
    />
  </div>
</template>

<script>
import ContactLineForm from '@/components/ContactLineForm';

import { contactLines } from '@/config/endpoints';
import { defaultOpeningHours } from '@/config';
import { SHOW_TOAST } from '@/constants';
import { sanitizeContactLine } from './utils';

const initialValues = {
  active: true,
  channelId: undefined,
  entityId: undefined,
  information: '',
  closed: '',
  exceptionallyClosed: '',
  openingHours: [...defaultOpeningHours],
  exceptions: [],
};

export default {
  name: 'NewContactLine',

  props: ['data'],

  components: {
    ContactLineForm,
  },

  computed: {
    localValues() {
      const { channels, entities } = this.data;

      return {
        channels,
        entities,
      };
    },

    initialValues() {
      return initialValues;
    },
  },

  methods: {
    handleCancel() {
      this.$router.push('/');
    },

    handleSubmit(dirtyData) {
      const newLine = { ...initialValues, ...dirtyData };

      this.$api(contactLines, {
        method: 'put',
        body: sanitizeContactLine(newLine),
      })
        .then(this.handleSuccess)
        .catch(this.handleError);
    },

    handleSuccess() {
      this.$store.dispatch(SHOW_TOAST, {
        message: 'Contactlijn toegevoegd',
        type: 'is-success',
      });

      this.$router.push('/');
    },

    handleError(error) {
      console.log({ error });
      this.$store.dispatch(SHOW_TOAST, {
        message: error.message,
        type: 'is-danger',
      });
    },
  },
};
</script>
