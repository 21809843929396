<template>
  <Loading />
</template>

<script>
export default {
  created() {
    this.$auth.handleAuthentication().then(() => this.$router.replace('/'));
  },
};
</script>
