<template>
  <div class="field">
    <div class="control has-icons-left">
      <div class="select">
        <select v-bind:value="value" v-on:input="$emit('input', $event.target.value)">
          <option
            v-for="(dayName, index) in dayNames"
            :key="dayName"
            :value="index">
            {{ dayName }}
          </option>
        </select>
      </div>

      <Icon class="is-left" iconName="calendar" />
    </div>
  </div>
</template>

<script>
import { Icon } from '@/components/shared';
import { dayNames } from '@/config';

export default {
  components: { Icon },

  props: ['value'],

  data() {
    return {
      dayNames,
    };
  },
};
</script>

<style scoped>
.select select:not(:hover),
input {
  border-color: transparent;
  box-shadow: none;
}
</style>
