<template>
  <form @submit.prevent="handleSubmit">
    <HorizontalWrapper label="Is actief?">
      <div class="flex items-center">
        <div class="mr-4" :style="{ marginTop: '0.375em' }">
          <input type="checkbox" id="isActive" class="switch" v-model="values.active" />

          <label for="isActive" />
        </div>

        <div class="has-text-danger">
          <Icon iconName="alert" />
          
          Let op: Dit sluit alle contactlijnen.
        </div>
      </div>
    </HorizontalWrapper>

    <HorizontalWrapper label="Informatietekst wanneer niet actief">
      <input v-model="values.exceptionally_closed" type="text" class="input" />
    </HorizontalWrapper>

    <ConfirmOrCancel :onCancel="onCancel" :onSubmit="handleSubmit" />
  </form>
</template>

<script>
import { compose, propEq, toPairs, reduce } from 'ramda';

import { settings } from '@/config/endpoints';
import { ConfirmOrCancel, HorizontalWrapper } from '@/components/FormFields';
import { SHOW_TOAST } from '@/constants';

const getSettings = reduce(
  (acc, { name, value }) => ({
    ...acc,
    [name]: value,
  }),
  {},
);

export default {
  props: ['data', 'onCancel', 'onSubmit'],

  data() {
    const { active, exceptionally_closed } = getSettings(this.data.settings);

    return {
      values: {
        active: !!parseInt(active),
        exceptionally_closed,
      },
    };
  },

  components: { ConfirmOrCancel, HorizontalWrapper },

  methods: {
    handleError({ message }) {
      this.$store.dispatch(SHOW_TOAST, {
        message,
        type: 'is-danger',
      });
    },

    handleSubmit() {
      const findSettingByName = name =>
        this.data.settings.find(propEq('name', name));
      const maybeGetIdFromSetting = (setting = {}) => setting.id;
      const maybeGetIdByName = compose(
        maybeGetIdFromSetting,
        findSettingByName,
      );

      const makeBody = name => ({
        id: maybeGetIdByName(name),
        name,
        value: this.values[name],
      });

      const makePromise = ({ id, ...body }) =>
        this.$api(`${settings}/${id}`, {
          method: 'patch',
          body,
        });

      const makePromiseFromKey = compose(
        makePromise,
        makeBody,
      );

      const originalSettings = getSettings(this.data.settings);

      const promises = toPairs(this.values).reduce(
        (acc, [key, value]) =>
          value == originalSettings[key]
            ? acc
            : [...acc, makePromiseFromKey(key)],
        [],
      );

      Promise.all(promises)
        .then((result, err) => {
          if (err) throw err;

          this.$router.push('/');
          this.handleSuccess('Instellingen bewerkt');
        })
        .catch(this.handleError);
    },

    handleSuccess(message) {
      this.$store.dispatch(SHOW_TOAST, {
        message,
        type: 'is-success',
      });
    },
  },
};
</script>
