<template>
  <div>
    <div class="flex justify-between flex-wrap">
      <h3 class="title mb-6">Contactlijn bewerken</h3>

      <button class="button is-danger" @click="showModal = true">
        <Icon iconName="delete-outline"/>

        <span>Contactlijn verwijderen</span>
      </button>
    </div>

    <ContactLineForm
      :channels="data.channels"
      :entities="data.entities"
      :initialValues="data.contactLine"
      :onSubmit="handleSubmit"
      :onCancel="handleCancel"
      :isEdit="true"
    />

    <Modal v-if="showModal" @closeModal="showModal = false">
      <AreYouSure :onConfirm="handleDelete" :onCancel="() => showModal = false"/>
    </Modal>
  </div>
</template>

<script>
import ContactLineForm from '@/components/ContactLineForm';
import { HorizontalWrapper } from '@/components/FormFields';
import { contactLineById } from '@/config/endpoints';
import { SHOW_TOAST } from '@/constants';

import { sanitizeContactLine } from './utils';

export default {
  name: 'EditContactLine',

  props: ['data'],

  components: { ContactLineForm, HorizontalWrapper },

  data() {
    return {
      showModal: false,
    };
  },

  methods: {
    handleCancel() {
      this.$router.push('/');
    },

    handleDelete() {
      this.$api(contactLineById(this.data.contactLine.id), {
        method: 'delete',
      })
        .then(() => this.handleSuccess('Contactlijn verwijderd'))
        .catch(this.handleError);
    },

    handleError(error) {
      console.log({ error });
      this.showModal = false;

      this.$store.dispatch(SHOW_TOAST, {
        message: error.message,
        type: 'is-danger',
      });
    },

    handleSubmit(dirtyData) {
      this.$api(contactLineById(this.data.contactLine.id), {
        body: sanitizeContactLine(dirtyData),
        method: 'patch',
      })
        .then(() => this.handleSuccess('Contactlijn bewerkt'))
        .catch(this.handleError);
    },

    handleSuccess(message) {
      this.$store.dispatch(SHOW_TOAST, {
        message,
        type: 'is-success',
      });

      this.$router.push('/');
    },
  },
};
</script>
