<template>
  <form @submit.prevent="handleSubmit">
      <HorizontalWrapper label="Entiteit" :isNarrow="true">
        <div class="flex items-center">
          <div class="select is-fullwidth mr-4">
            <select v-model="entityId" required :disabled="isEdit">
              <option :value="undefined" disabled>Kies een entiteit</option>
              <option v-for="{ id, name } in entities" :key="id" :value="id">{{ name }}</option>
            </select>
          </div>

          <button type="button" class="button" v-if="!isEdit" @click.prevent="$router.push({ name: 'newEntity' })">
            <Icon iconName="plus" />

            <span>Nieuwe entiteit toevoegen</span>
          </button>
        </div>
      </HorizontalWrapper>

      <HorizontalWrapper label="Kanaal" :isNarrow="true">
        <div class="flex items-center">
          <div class="select is-fullwidth mr-4">
            <select v-model="channelId" required :disabled="isEdit">
              <option :value="undefined" disabled>Kies een kanaal</option>
              <option v-for="{ id, name } in channels" :key="id" :value="id">{{ name }}</option>
            </select>
          </div>

          <button type="button" class="button" v-if="!isEdit"  @click.prevent="$router.push({ name: 'newChannel' })">
            <Icon iconName="plus" />

            <span>Nieuw kanaal toevoegen</span>
          </button>
        </div>
      </HorizontalWrapper>

      <HorizontalWrapper label="Gecreeerd" v-if="isEdit">
        <input :value="created | dateTime" type="text" class="input" disabled />
      </HorizontalWrapper>

      <HorizontalWrapper label="Laatst geupdate" v-if="isEdit">
        <input :value="updated | dateTime" type="text" class="input" disabled />
      </HorizontalWrapper>

      <HorizontalWrapper label="Is open?" v-if="isEdit">
        <div class="field flex" :style="{ marginTop: '0.375em' }">
          <YesOrNo :value="isOpen" />

          <div class="ml-4">
            <p v-if="!isOpen">{{ prettyReason }}</p>
          </div>
        </div>
      </HorizontalWrapper>

      <HorizontalWrapper label="Is actief?">
        <div class="field flex" :style="{ marginTop: '0.375em' }">
          <div>
            <input type="checkbox" id="isActive" class="switch" v-model="active" />

            <label for="isActive" />
          </div>
        </div>
      </HorizontalWrapper>

      <HorizontalWrapper label="Informatietekst regulier">
        <input type="text" class="input" v-model="information" placeholder="bijv. Geopend elke werkdag van 09u00 tot 17u00" />
      </HorizontalWrapper>

      <HorizontalWrapper label="Informatietekst gesloten">
        <input type="text" class="input" v-model="closed" placeholder="bijv. We zijn momenteel gesloten" />
      </HorizontalWrapper>

      <HorizontalWrapper label="Informatietekst uitzonderlijk gesloten">
        <input type="text" class="input" v-model="exceptionallyClosed" placeholder="bijv. We zijn uitzonderlijk gesloten" />
      </HorizontalWrapper>

      <HorizontalWrapper label="Openingsuren">
        <OpeningHoursTable v-model="openingHours" />
      </HorizontalWrapper>

      <HorizontalWrapper label="Uitzonderingen">
        <ExceptionsTable v-model="exceptions" />
      </HorizontalWrapper>

      <div class="field is-horizontal">
        <div class="field-label" />

        <div class="field-body">
          <div class="field is-grouped">
            <div class="control">
              <button type="submit" class="button is-primary">
                <Icon iconName="content-save-outline" />

                <span>Bewaren</span>
              </button>
            </div>

            <div class="control">
              <button type="button" class="button" @click.prevent="onCancel">
                <Icon iconName="cancel" />

                <span>Annuleren</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
</template>

<script>
import { find, propEq } from 'ramda';
import {
  ExceptionsTable,
  HorizontalWrapper,
  OpeningHoursTable,
} from '@/components/FormFields';
import { reasonsForBeingClosed } from '@/config';
import { dirtyData } from '@/mixins';

export default {
  mixins: [dirtyData],

  props: [
    'channels',
    'entities',
    'isEdit',
    'onAddChannel',
    'onAddEntity',
    'onCancel',
  ],

  components: { ExceptionsTable, HorizontalWrapper, OpeningHoursTable },

  computed: {
    currentChannel() {
      return find(propEq('id', this.channelId), this.channels);
    },

    currentEntity() {
      return find(propEq('id', this.entityId), this.entities);
    },

    prettyReason() {
      return this.isOpen
        ? null
        : reasonsForBeingClosed[this.reasonForBeingClosed];
    },
  },
};
</script>
