<template>
  <div class="box relative" :style="{ overflowX: 'auto' }">
    <Table
      :data="sortedData || []"
      :config="config"
      :onRowClick="onRowClick"
      :sortByKey="sortByKey"
      :sortDirection="sortDirection"
      :updateSort="updateSort" />
  </div>
</template>

<script>
import * as R from 'ramda';

import Loading from '@/components/shared/Loading';
import SearchField from '@/components/shared/SearchField';
import Table from '@/components/shared/Table';

import { findInArrayByPropEnforcing, reverseDirection } from '@/utils';

export default {
  components: { Loading, SearchField, Table },

  props: [
    'config',
    'data',
    'filter',
    'filterBy',
    'onRowClick',
    'defaultSortByKey',
    'defaultSortDirection',
  ],

  data() {
    return {
      sortByKey:
        this.defaultSortByKey ||
        R.compose(
          R.prop('key'),
          R.head,
        )(this.config),
      sortDirection: this.defaultSortDirection || 'ASC',
    };
  },

  computed: {
    filteredData() {
      if (!this.filterBy || !this.filter) {
        return this.data;
      }

      const queries = R.compose(
        R.map(R.toLower),
        R.split(' '),
        R.trim,
      )(this.filter);

      const isHit = entry => {
        const strings = this.filterBy
          .map(
            R.compose(
              R.path(R.__, entry),
              R.split('.'),
            ),
          )
          .map(R.toLower);

        const hasQuery = query =>
          strings.reduce(
            (acc, string) => acc || R.contains(query, string),
            false,
          );

        return queries.reduce(
          (acc, query) => (acc === false ? false : hasQuery(query)),
          true,
        );
      };

      return this.data.filter(isHit);
    },

    sortedData() {
      const { cellDataGetter, sortDataGetter } = findInArrayByPropEnforcing(
        this.config,
        'key',
        this.sortByKey,
      );

      const sortedData = R.sortBy(
        sortDataGetter || cellDataGetter,
        this.filteredData,
      );

      return this.sortDirection === 'DESC' ? R.reverse(sortedData) : sortedData;
    },
  },

  methods: {
    updateSort(newSortByKey) {
      this.sortDirection =
        newSortByKey === this.sortByKey
          ? reverseDirection(this.sortDirection)
          : this.sortDirection;

      this.sortByKey = newSortByKey;
    },
  },
};
</script>
