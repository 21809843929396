export const channels = 'channels';
export const contactLines = 'contact_lines';
export const entities = 'entities';
export const events = 'events';
export const openingHours = 'opening_hours_defaults';
export const settings = 'settings';
export const version = 'version';

export const contactLineById = id => `${contactLines}/${id}`;
export const openingHoursById = id => `${openingHours}/${id}`;
export const openingHoursByContactLineId = id =>
  `${contactLines}/${id}/${openingHours}`;
export const entityById = id => `${entities}/${id}`;
export const channelById = id => `${channels}/${id}`;
