<template>
  <tr>
    <th class="align-middle">{{ index + 1 }}</th>

    <td>
      <Daypicker v-model="localValue.startDay" />
    </td>

    <td>
      <TimePicker v-model="localValue.startTime" />
    </td>

    <td>
      <Daypicker v-model="localValue.endDay" />
    </td>

    <td>
      <TimePicker v-model="localValue.endTime" />
    </td>

    <td>
      <button type="button" class="button is-danger" @click.prevent="handleDelete">
        <Icon iconName="close" />
      </button>
    </td>
  </tr>
</template>

<script>
import Daypicker from './Daypicker';
import TimePicker from './TimePicker';
import { Icon } from '@/components/shared';

export default {
  components: { Daypicker, Icon, TimePicker },

  props: ['value', 'index', 'onDelete'],

  computed: {
    localValue() {
      return this.value;
    },
  },

  methods: {
    handleDelete() {
      this.onDelete(this.index);
    },
  },
};
</script>
