<template>
  <div class="box inline-block">
    <table class="table" v-if="value.length > 0">
      <thead>
        <tr>
          <th>#</th>
          <th colspan="2">Van</th>
          <th colspan="3">Tot</th>
        </tr>
      </thead>
      <tbody>
        <OpeningPeriod
          v-for="(period, index) in value"
          :key="index"
          :index="index"
          :onDelete="handleDeleteOpeningPeriod"
          v-bind:value="period" />
      </tbody>
    </table>

    <button type="button" class="button" @click.prevent="handleAddOpeningPeriod">
      <Icon iconName="plus" />

      <span>Periode toevoegen</span>
    </button>
  </div>
</template>

<script>
import { remove } from 'ramda';
import OpeningPeriod from './OpeningPeriod';

import { getDefaultPeriod } from '@/config';

export default {
  components: { OpeningPeriod },

  props: ['value'],

  methods: {
    handleAddOpeningPeriod() {
      const defaultDay = 1; // Monday;

      this.$emit('input', [...this.value, getDefaultPeriod(defaultDay)]);
    },

    handleDeleteOpeningPeriod(index) {
      this.$emit('input', remove(index, 1, this.value));
    },
  },
};
</script>
