import { clone, equals, toPairs } from 'ramda';

export default {
  props: ['initialValues', 'onSubmit'],

  data() {
    return clone(this.initialValues);
  },

  computed: {
    dirtyData() {
      return toPairs(this.$data).reduce(
        (acc, [key, val]) =>
          equals(val, this.initialValues[key])
            ? acc
            : {
                ...acc,
                [key]: this.$data[key],
              },
        {},
      );
    },
  },

  methods: {
    handleSubmit() {
      this.onSubmit(this.dirtyData);
    },
  },
};
