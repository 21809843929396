<template>
  <div>
    <div class="flex justify-between flex-wrap">
      <h3 class="title">Lijst van kanalen</h3>
      
      <div class="flex">
        <router-link class="button is-primary mr-4" :to="{ name: 'newChannel' }">
          <span class="icon">
            <i class="mdi mdi-24px mdi-plus" />
          </span>

          <span>Nieuw kanaal toevoegen</span>
        </router-link>

        <SearchField @change="filter = $event" />
      </div>
    </div>

    <TableContainer
      :config="tableConfig"
      :data="tableData"
      :filter="filter"
      :filterBy="['name']"
      :onRowClick="handleOnRowClick" />
  </div>
</template>

<script>
import { compose, prop } from 'ramda';
import { dateTime } from '@/filters';

import { SearchField, TableContainer, YesOrNo } from '@/components/shared';

const tableConfig = [
  {
    key: 'id',
    label: 'ID',
    cellDataGetter: prop('id'),
  },
  {
    key: 'name',
    label: 'Naam',
    cellDataGetter: prop('name'),
  },
  {
    key: 'lastUpdated',
    label: 'Geupdate',
    cellDataGetter: compose(
      dateTime,
      prop('updated'),
    ),
    sortDataGetter: prop('updated'),
  },
  {
    key: 'isActive',
    label: 'Is actief?',
    cellDataGetter: prop('active'),
    cellRenderer: YesOrNo,
  },
];

export default {
  name: 'Channels',

  props: ['data'],

  components: { SearchField, TableContainer },

  data() {
    return {
      filter: '',
      tableConfig,
      tableData: this.data.channels,
    };
  },

  methods: {
    handleOnRowClick({ id }) {
      this.$router.push({ name: 'editChannel', params: { id } });
    },
  },
};
</script>
