import { HIDE_TOAST, SHOW_TOAST } from '@/constants';

const TIMEOUT = 5000;

export default {
  state: {
    message: '',
    type: null,
  },

  mutations: {
    [HIDE_TOAST]: state => {
      state.message = '';
      state.type = null;
    },

    [SHOW_TOAST]: (state, { message, type }) => {
      state.message = message;
      state.type = type;
    },
  },

  actions: {
    [SHOW_TOAST]: ({ commit }, payload) => {
      commit(SHOW_TOAST, payload);

      setTimeout(() => {
        commit(HIDE_TOAST);
      }, TIMEOUT);
    },
  },
};
