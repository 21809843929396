<template>
  <div class="section flex flex-col flex-grow justify-center items-center">
    <div class="content">
      <div class="card">
        <div class="card-content">
          <h3 class="font-normal">Log in met uw e-mail adres</h3>

          <form class="form" @submit.prevent="handleSubmit">
            <div class="field">
              <label class="label font-bold">E-mail adres</label>

              <div class="control">
                <input class="input" type="email" placeholder="uw.naam@vlaanderen.be" v-model="formData.username" required>
              </div>
            </div>

            <div class="field">
              <label class="label font-bold">Wachtwoord</label>

              <div class="control">
                <input class="input" type="password" placeholder="Wachtwoord" v-model="formData.password" required>
              </div>
            </div>

            <div class="field">
              <div class="control">
                <button class="button is-primary w-full" :disabled="isLoading">
                  Log in
                </button>
              </div>
            </div>
          </form>

          <div class="field has-text-centered mt-4">
            <button class="button is-text" @click.prevent="handleResetPassword">Wachtwoord vergeten?</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SHOW_TOAST } from '@/constants';

export default {
  data() {
    return {
      formData: {
        username: '',
        password: '',
      },
      isLoading: false,
    };
  },

  methods: {
    handleSubmit() {
      this.isLoading = true;

      this.$auth.loginWithEmail(
        this.formData,
        undefined,
        this.handleAuthResponse,
      );
    },

    handleResetPassword() {
      this.$auth.resetPassword(this.formData.username, this.handleAuthResponse);
    },

    handleAuthResponse(error, response) {
      this.isLoading = false;

      const payload = error
        ? { message: error.description, type: 'is-danger' }
        : { message: response, type: 'is-success' };

      this.$store.dispatch(SHOW_TOAST, payload);
    },
  },
};
</script>
